import React, { Component } from "react";
import { connect } from "react-redux";
import { saveLogin } from "../../redux/actions/loginActions";
import { fetchUser } from "../../redux/actions/userActions";
import { Navigation } from "react-minimal-side-navigation";
import "react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css";

import { Dashboard, Basket, Archive, Money, Configure, Cubes, Package, Group } from "grommet-icons";

class SidebarMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      menu: [],
    };
  }

  componentDidMount() {
    let menu = [];

      //START IF SYSTEM === PAULINE

      if (this.props.user.data.role === "owner" || this.props.user.data.role === "admin") {
        menu.push({
          title: "Dashboard",
          itemId: "/dashboard",
          elemBefore: () => <Dashboard />,
        });
      }

      let subnav = [
        {
          title: "Sales Order",
          itemId: "/sales-order",
        },
      ];
      
      if(this.props.user.data.vars.retur === 'yes' && this.props.user.data.role === "owner"){
        subnav.push({
            title: "Sales Order Retur",
            itemId: "/sales-order-retur",
          });
      }
      
      subnav.push({
          title: "Customers",
          itemId: "/customers",
        });

      if (this.props.user.data.role === "owner") {
        subnav.push({
            title: "Store",
            itemId: "/salesman",
          });
      }
      
      if(this.props.user.data.vars.subsystem === 'fsh' && this.props.user.data.role === "owner"){
        subnav.push(
        {
          title: "Warehouse",
          itemId: "/warehouse",
        },
        {
          title: "Couriers",
          itemId: "/couriers",
        });
      }

      menu.push({
        title: "Sales",
        itemId: "a",
        elemBefore: () => <Basket />,
        subNav: subnav,
      });

      subnav = [
        {
          title: "Purchase Order",
          itemId: "/purchase-order",
        },
        /*{
          title: "Purchase List",
          itemId: "/purchase-list",
        },*/
      ];
      
      if(this.props.user.data.vars.retur === 'yes' && this.props.user.data.role === "owner"){
        subnav.push({
            title: "Purchase Order Retur",
            itemId: "/purchase-order-retur",
          },);
      }
      
      subnav.push({
        title: "Suppliers",
        itemId: "/suppliers",
      });

      if (this.props.user.data.role === "owner" /* || this.props.user.data.role === 'admin'*/) {
        menu.push({
          title: "Purchases",
          itemId: "b",
          elemBefore: () => <Package />,
          subNav: subnav,
        });
      }

      subnav = [
        {
          title: "Catalog",
          itemId: "/catalog",
        },
        {
          title: "Inventory",
          itemId: "/inventory",
        },
        /*{
          title: "Colors",
          itemId: "/colors",
        },
        {
          title: "Sizes",
          itemId: "/sizes",
        },*/
      ];
      
      if (this.props.user.data.vars.subsystem === 'fsh' && (this.props.user.data.role === "owner" || this.props.user.data.role === "admin" || this.props.user.data.role === "warehouse")) {
        subnav.push({
          title: "Mutasi",
          itemId: "/mutasi",
        });
      }
      
      if(this.props.user.data.vars.subsystem !== 'waluyojaya'){
        subnav.push({
          title: "Categories",
          itemId: "/categories",
        },
        {
          title: "Units",
          itemId: "/units",
        });
      }

      if (this.props.user.data.role === "owner") {
        subnav.push({
          title: "Warehouse",
          itemId: "/warehouse",
        });
      }

      if (this.props.user.data.role === "owner" || this.props.user.data.role === "admin" || this.props.user.data.role === "warehouse") {
        menu.push({
          title: "Items",
          itemId: "c",
          elemBefore: () => <Archive />,
          subNav: subnav,
        });
      }
      
      if (this.props.user.data.role === "sales") {
        menu.push({
          title: "Items",
          itemId: "c",
          elemBefore: () => <Archive />,
          subNav: [{
            title: "Inventory",
            itemId: "/inventory",
          }],
        });
      }

      if (
        this.props.user.data.vars.productions !== undefined &&
        this.props.user.data.vars.productions === "yes"
      ) {
        subnav = [
          {
            title: "Production Batch",
            itemId: "/production-batch",
          },
          {
            title: "Inventory",
            itemId: "/production-inventory",
          },
          {
            title: "Vendors",
            itemId: "/vendors",
          },
        ];

        if (this.props.user.data.role === "owner" || this.props.user.data.role === "admin") {
          menu.push({
            title: "Productions",
            itemId: "d",
            elemBefore: () => <Cubes />,
            subNav: subnav,
          });
        }
      }

      subnav = [
        {
          title: "Transactions",
          itemId: "/transactions",
        },
      ];

      if (this.props.user.data.role === "owner" || (this.props.user.data.vars.subsystem === 'waluyojaya' && this.props.user.data.role === "admin")) {
        subnav.push({
          title: "Accounts",
          itemId: "/accounts",
        });
      }

      if (this.props.user.data.role === "owner") {
        subnav.push({
          title: "Reports",
          itemId: "/reports",
        });
      }

      if (this.props.user.data.role === "owner" || this.props.user.data.role === "admin") {
        menu.push({
          title: "Finances",
          itemId: "e",
          elemBefore: () => <Money />,
          subNav: subnav,
        });
      }

      if (this.props.user.data.role === "owner") {
        menu.push({
          title: "Settings",
          itemId: "/settings",
          elemBefore: () => <Group />,
        });
      }

      //NOT READY YET
      // { /*
      // if(this.props.user.data.role === 'owner'){
      //   menu.push({
      //     title: 'Statistic',
      //     itemId: '/statistic',
      //     elemBefore: () => <BarChart />,
      //   });
      // }

      // if(this.props.user.data.role === 'owner'){
      //   menu.push({
      //     title: 'Settings',
      //     itemId: '/settings',
      //     elemBefore: () => <Configure />,
      //   });
      // }*/ }
    
    this.setState({ menu: menu });
  }

  render() {
    return (
      <>
        <Navigation
          // you can use your own router's api to get pathname
          activeItemId={window.location.pathname}
          onSelect={({ itemId }) => {
            if (itemId.toString().length > 1) {
              this.props.history.replace(itemId);
            }
          }}
          items={this.state.menu}
        />
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    login: state.loginData,
    user: state.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveLogin: (id_user, api_token) => {
      dispatch(saveLogin(id_user, api_token));
    },
    fetchUser: (userdata) => {
      dispatch(fetchUser(userdata));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SidebarMenu);
