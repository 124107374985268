import axios from 'axios'
import { Config } from './Config'
import { is, curryN, gte } from 'ramda'

const gfa = 500

const baseurl = Config.API_URL;
const token = Config.API_TOKEN;

const isWithin = curryN(3, (min, max, value) => {
  const isNumber = is(Number)
  return isNumber(min) && isNumber(max) && isNumber(value) && gte(value, min) && gte(max, value)
})
const in200s = isWithin(200, 299)

export const apiconn = {
	gfa: gfa,
	in200s: in200s,
	postApi: axios.create({
		  baseURL: baseurl,
		  headers: {
		    'Accept': 'application/json',
		    'Content-Type': 'application/json',
		    'Authorization': 'Basic ' + token,
		  },
		  method: 'post',
		  timeout: 30000,
		}),
	postFileApi: axios.create({
		  baseURL: baseurl,
		  headers: {
		    'Accept': 'application/json',
		    'Content-Type': 'multipart/form-data',
		    'Authorization': 'Basic ' + token,
		  },
		  method: 'post',
		  timeout: 30000,
		}),
	getApi: axios.create({
		  baseURL: baseurl,
		  headers: {
		    'Accept': 'application/json',
		    'Content-Type': 'application/json',
		    'Authorization': 'Basic ' + token,
		  },
		  method: 'get',
		  timeout: 30000,
		}),
	putApi: axios.create({
		  baseURL: baseurl,
		  headers: {
		    'Accept': 'application/json',
		    'Content-Type': 'application/json',
		    'Authorization': 'Basic ' + token,
		  },
		  method: 'put',
		  timeout: 30000,
		}),
	delApi: axios.create({
		  baseURL: baseurl,
		  headers: {
		    'Accept': 'application/json',
		    'Content-Type': 'application/json',
		    'Authorization': 'Basic ' + token,
		  },
		  method: 'delete',
		  timeout: 30000,
		}),
}